import {
  Autocomplete,
  Box,
  Button,
  Card,
  Modal,
  Stack,
  TextField,
  Typography,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getAllOrgAPI } from "../../redux/orgnisation/getAllOrg";
import { getAllSlbsAPI } from "../../redux/slb/getAllSlbs";
import { revertSLBsFromOrgAPI } from "../../redux/slb/revertSLBsFromOrg";

function RevertSLBsFromSLB({ open, setOpen, asignData }) {
  const getAllOrgSelector = useSelector((state) => state?.getAllOrg);
  const { result } = getAllOrgSelector;

  const revertSLBsFromOrgSelector = useSelector(
    (state) => state?.revertSLBsFromOrg
  );
  const { message } = revertSLBsFromOrgSelector;

  const dispatch = useDispatch();

  const [orgDetails, setOrgDetails] = useState();
  // const [slbId, setSlbId] = useState();

  console.log("assignData",asignData);

  const handleRevertSLBFromOrg = async () => {
    await dispatch(
      revertSLBsFromOrgAPI({ OrgId: orgDetails, slbId: asignData })
    );
    await dispatch(getAllSlbsAPI());
    setOpen(false);
  };

  const handleClose = () => setOpen(false);

  useEffect(() => {
    dispatch(getAllOrgAPI());
  }, []);

  return (
    <>
      <Modal open={open} onClose={handleClose}>
        <Box className="modal">
          <Card sx={{ p: 2, width: 600 }}>
            <Stack spacing={2}>
              <Typography variant="h6">
                Revert SLBs from an Organization!
              </Typography>
              <Autocomplete
                disablePortal
                options={result}
                getOptionLabel={(option) => option.name}
                fullWidth
                onChange={(e, value) => {
                  setOrgDetails(value?._id);
                }}
                renderInput={(params) => (
                  <TextField {...params} label="Select org" />
                )}
              />
              <Button onClick={handleRevertSLBFromOrg} variant="contained">
                Revert
              </Button>
            </Stack>
          </Card>
        </Box>
      </Modal>
    </>
  );
}

export default RevertSLBsFromSLB;
