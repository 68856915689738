import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import Axios from "../../config/authAxios";
import toast from "react-hot-toast";

const initialState = { loading: false, result: [], error: null, message: "" };

export const assignSlbsToUserAPI = createAsyncThunk(
  "assign-slbs-to-user",
  async (data, { rejectWithValue }) => {
    try {
      const response = await Axios.post(`/slbs/assign-to-user`, data);
      // debugger
      console.log(response)
      if (response.data.status === 409) {
        toast.error(response.data.status, {
          duration: 5000,
          position: "bottom-left",
        });
      }


      // if webhook user registered
      const iswebhookRes = response.data.result.webhookRes;
      const webhookmsg = response.data.result.webhookmsg;
      if (iswebhookRes && webhookmsg !== "") {
        toast.success(webhookmsg, {
          duration: 5000,
          position: "bottom-left",
        });
      }

      return await response.data;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);

const counterSlice = createSlice({
  name: "assign-slbs-to-user",
  initialState,
  extraReducers: (builder) => {
    builder
      .addCase(assignSlbsToUserAPI.pending, (state, action) => {
        state.loading = true;
      })
      .addCase(assignSlbsToUserAPI.fulfilled, (state, action) => {
        state.loading = false;
        state.result = action.payload?.result;
        state.message = action.payload?.message;
      })
      .addCase(assignSlbsToUserAPI.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload?.error;
        state.message = action.payload?.message;
      });
  },
});

const assignSlbstoUserReducer = counterSlice.reducer;

export default assignSlbstoUserReducer;
