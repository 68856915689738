// import React, { useEffect, useState } from "react";

// import { Tooltip } from "@mui/material";
// import {
//   Button,
//   CircularProgress,
//   Modal,
//   Paper,
//   Stack,
//   Typography,
//   Box,
//   TextField,
// } from "@mui/material";
// import { DataGrid } from "@mui/x-data-grid";
// import { CSVLink } from "react-csv";
// import CloudDownloadIcon from "@mui/icons-material/CloudDownload";
// import Appbar from "../../ui/Appbar";
// import Body from "../../ui/Body";
// import SideBar from "../../ui/SideBar";
// import Axios from "../../config/authAxios";

// // Normalize Orders Data
// const normalizeOrders = (orders) => {
//   console.log("nor", orders);
//   return orders.map((order) => ({
//     orderId: order._id,
//     buyer: {
//       name: order?.buyerId?.name || "N/A",
//       email: order?.buyerId?.email || "N/A",
//     },
//     seller: {
//       name: order?.sellerId?.name || "N/A",
//       email: order?.sellerId?.email || "N/A",
//       role: order?.sellerId?.role || "N/A",
//       sellerPayout: order?.sellerId?.payout || "N/A",
//       sellercryptoWallet: order?.sellerId?.cryptoWallet || "N/A",
//     },
//     price: {
//       inr: order?.price?.inr || "N/A",
//       usd: order?.price?.usd || "N/A",
//     },
//     creator: {
//       id: order?.creatorId?._id || "N/A",
//       name: order?.creatorId?.name || "N/A",
//       email: order?.creatorId?.email || "N/A",
//       role: order?.creatorId?.role || "N/A",
//       creatorPayout: order?.creatorId?.payout || "N/A",
//       createrCryptoWallet: order?.creatorId?.cryptoWallet || "N/A",
//     },
//     creatorPayment: {
//       amountINR: order?.creatorPayment?.amountINR || "N/A",
//       amountUSD: order?.creatorPayment?.amountUSD || "N/A",
//       status: order?.creatorPayment?.status || "N/A",
//       transactionId: order?.creatorPayment?.transactionId || "N/A",
//     },
//     sellerPayment: {
//       amountINR: order?.sellerPayment?.amountINR || "N/A",
//       amountUSD: order?.sellerPayment?.amountUSD || "N/A",
//       status: order?.sellerPayment?.status || "N/A",
//       transactionId: order?.sellerPayment?.transactionId || "N/A",
//     },
//     sellerBankDetails: {
//       accountName: order?.sellerId?.bankDetail?.name,
//       accountNumber: order?.sellerId?.bankDetail?.accountNumber,
//       accountType: order?.sellerId?.bankDetail?.accountType,
//       branchName: order?.sellerId?.bankDetail?.branchName,
//       ifscCode: order?.sellerId?.bankDetail?.ifscCode,
//       name: order?.sellerId?.bankDetails?.name,
//     },
//     creatorBankDetails: {
//       accountName: order?.creatorId?.bankDetail?.name,
//       accountNumber: order?.creatorId?.bankDetail?.accountNumber,
//       accountType: order?.creatorId?.bankDetail?.accountType,
//       branchName: order?.creatorId?.bankDetail?.branchName,
//       ifscCode: order?.creatorId?.bankDetail?.ifscCode,
//       name: order?.creatorId?.bankDetails?.name,
//     },
//     creatorPayments: {
//       amountINR: order?.creatorPayment?.amountINR || "N/A",
//       amountUSD: order?.creatorPayment?.amountUSD || "N/A",
//     },
//     royaltyFee: order?.clbId?.royaltyFee?.percentage || "N/A",
//     sellerPayment: order?.sellerPayment || {},

//     createdAt: new Date(order?.created_at).toLocaleDateString(),
//   }));
// };

// const fetchOrders = async () => {
//   try {
//     const response = await Axios.get("/orders-management/get-order-management");
//     console.log("ressssssssss", response.data.result);
//     if (response) {
//       return normalizeOrders(response.data.result);
//     } else {
//       console.error("Error fetching orders:", response.data.message);
//       return [];
//     }
//   } catch (error) {
//     console.error("API call failed:", error);
//     return [];
//   }
// };

// function Table() {
//   const [orders, setOrders] = useState([]);
//   const [loading, setLoading] = useState(true);
//   const [csvData, setCsvData] = useState([]);
//   const [openModal, setOpenModal] = useState(false);
//   const [modalData, setModalData] = useState({});
//   const [formData, setFormData] = useState({});
//   const [submitting, setSubmitting] = useState(false);
//   const [filteredOrders, setFilteredOrders] = useState([]);
//   const [searchQuery, setSearchQuery] = useState("");

//   useEffect(() => {
//     const getOrders = async () => {
//       const fetchedOrders = await fetchOrders();
//       setOrders(fetchedOrders);
//       setLoading(false);
//     };
//     getOrders();
//   }, []);
//   useEffect(() => {
//     const lowercasedQuery = searchQuery.toLowerCase();

//     const filtered = orders.filter((order) => {
//       // Combine all searchable values into a single array
//       const searchableValues = [
//         order?.buyer?.name,
//         order?.buyer?.email,
//         order?.seller?.name,
//         order?.seller?.email,
//         order?.seller?.role,
//         order?.seller?.sellerPayout,
//         order?.seller?.sellerCryptoWallet,
//         order?.creator?.name,
//         order?.creator?.role,
//         order?.creator?.email,
//         order?.creator?.creatorPayout,
//         order?.creator?.createrCryptoWallet,
//         order?.sellerBankDetails?.accountName,
//         order?.sellerBankDetails?.accountNumber,
//         order?.sellerBankDetails?.accountType,
//         order?.sellerBankDetails?.branchName,
//         order?.sellerBankDetails?.ifscCode,
//         order?.creatorBankDetails?.accountName,
//         order?.creatorBankDetails?.accountNumber,
//         order?.creatorBankDetails?.accountType,
//         order?.creatorBankDetails?.branchName,
//         order?.creatorBankDetails?.ifscCode,
//         order?.creatorPayments?.amountINR,
//         order?.creatorPayments?.amountUSD,
//         order?.creatorPayment?.status,
//         order?.creatorPayment?.transactionId,
//         order?.sellerPayment?.amountINR,
//         order?.sellerPayment?.amountUSD,
//         order?.sellerPayment?.status,
//         order?.sellerPayment?.transactionId,
//         order?.price?.inr,
//         order?.price?.usd,
//         order?.royaltyFee,
//         order?.orderId,
//       ];

//       // Check if any value matches the search query
//       return searchableValues.some((value) =>
//         value?.toString().toLowerCase().includes(lowercasedQuery)
//       );
//     });

//     setFilteredOrders(filtered);
//   }, [searchQuery, orders]);

//   const handleSearch = (e) => {
//     setSearchQuery(e.target.value);
//   };

//   useEffect(() => {
//     console.log("oreders", orders);
//     const csvData = orders.map((order) => ({
//       OrderID: order.orderId,
//       BuyerName: order.buyer.name,
//       BuyerEmail: order.buyer.email,
//       SellerName: order.seller.name,
//       SellerEmail: order.seller.email,
//       SellerRole: order.seller.role,
//       CreatorDetails: order.creatorId,
//       CreatorBank: order.creator.bank,
//       PriceINR: order.price.inr,
//       PriceUSD: order.price.usd,
//       createrPriceINR: order.creatorPayment.amountINR,
//       createrPriceUSD: order.creatorPayment.amountUSD,
//       createrStatus: order.creatorPayment.status,
//       createrTransactionId: order.creatorPayment.transactionId,
//       sellerPriceINR: order.sellerPayment.amountINR,
//       sellerPriceUSD: order.sellerPayment.amountUSD,
//       sellerStatus: order.sellerPayment.status,
//       sellerTransactionId: order.sellerPayment.transactionId,
//       RoyaltyFeePercentage: order.royaltyFee,
//       CreatedAt: order.createdAt,

//     }));
//     setCsvData(csvData);
//   }, [orders]);

//   const handleSubmit = async () => {
//     setSubmitting(true);
//     try {
//       const payload = {
//         orderId: modalData.orderId,
//         status: "completed",
//         ...(modalData.type === "Royalty Fee"
//           ? { creatorTransId: formData.transactionId }
//           : { sellerTransId: formData.transactionId }),
//       };

//       const response = await Axios.post(
//         "/orders-management/update-transaction",
//         payload
//       );

//       if (response && response.data) {
//         // alert("Transaction updated successfully");
//         setOpenModal(false);
//       } else {
//         // alert("Failed to update transaction");
//       }
//     } catch (error) {
//       console.error("Error submitting transaction:", error);
//       //   alert("An error occurred while updating the transaction");
//     } finally {
//       setSubmitting(false);
//     }
//   };

//   const renderModalContent = () => (
//     <Box
//       sx={{
//         position: "absolute",
//         top: "50%",
//         left: "50%",
//         transform: "translate(-50%, -50%)",
//         width: 400,
//         bgcolor: "background.paper",
//         boxShadow: 24,
//         p: 4,
//         borderRadius: 2,
//       }}
//     >
//       <Typography variant="h6" sx={{ mb: 2 }}>
//         {modalData.type}
//       </Typography>
//       <TextField
//         fullWidth
//         label="Transaction ID"
//         value={formData.transactionId || ""}
//         onChange={(e) =>
//           setFormData({ ...formData, transactionId: e.target.value })
//         }
//         sx={{ mb: 2 }}
//       />
//       <Button
//         variant="contained"
//         fullWidth
//         disabled={!formData.transactionId || submitting}
//         onClick={handleSubmit}
//       >
//         {submitting ? <CircularProgress size={24} /> : "Submit"}
//       </Button>
//       <Button
//         variant="outlined"
//         fullWidth
//         sx={{ mt: 1 }}
//         onClick={() => {
//           setOpenModal(false); // Close the modal
//           setFormData({}); // Clear the input fields
//           setModalData({}); // Optional: Reset modal-specific data
//         }}
//       >
//         Cancel
//       </Button>
//     </Box>
//   );

//   const columns = [
//     {
//       field: "buyerName",
//       headerName: "Buyer Name",
//       width: 160,
//       valueGetter: (params) => params.row.buyer.name,
//     },
//     {
//       field: "buyerEmail",
//       headerName: "Buyer Email",
//       width: 200,
//       valueGetter: (params) => params.row.buyer.email,
//     },
//     {
//       field: "sellerName",
//       headerName: "Seller Name",
//       width: 160,
//       valueGetter: (params) => params.row.seller.name,
//     },
//     {
//       field: "sellerEmail",
//       headerName: "Seller Email",
//       width: 200,
//       valueGetter: (params) => params.row.seller.email,
//     },
//     {
//       field: "SellerRole",
//       headerName: "Seller Role",
//       width: 200,
//       valueGetter: (params) => params.row.seller.role,
//     },
//     {
//       field: "sellerPayout",
//       headerName: "Seller Payout ",
//       width: 200,
//       renderCell: (params) => {
//         const creator = params?.row?.seller;

//         if (creator?.id !== "N/A") {
//           return (
//             <>
//               <Typography variant="body2">{creator?.sellerPayout || "N/A"}</Typography>
//             </>
//           );
//         }

//         return <Typography variant="body2">N/A</Typography>;
//       },
//     },
//     {
//       field: "sellerWalletAddress",
//       headerName: "Seller Wallet Address",
//       width: 200,
//       renderCell: (params) => {
//         const creator = params?.row?.seller;

//         if (creator?.id !== "N/A") {
//           return (
//             <>
//               <Typography variant="body2">{creator?.sellerCryptoWallet || "N/A"}</Typography>
//             </>
//           );
//         }

//         return <Typography variant="body2">N/A</Typography>;
//       },
//     },
//     // {
//     //   field: "creatorPayment",
//     //   headerName: "Creator Payment (INR)",
//     //   width: 250,
//     //   renderCell: (params) => {
//     //     const { amountINR, amountUSD, status, transactionId } =
//     //       params.row.creatorPayment || {};

//     //     // Tooltip content
//     //     const tooltipContent = `
//     //       USD: ${amountUSD !== undefined ? amountUSD : "N/A"}
//     //       Status: ${status || "N/A"}
//     //       Transaction ID: ${transactionId || "N/A"}
//     //     `;

//     //     return (
//     //       <Tooltip title={<pre>{tooltipContent}</pre>} arrow>
//     //         <Typography variant="body2">
//     //           {amountINR !== undefined ? amountINR : "N/A"}
//     //         </Typography>
//     //       </Tooltip>
//     //     );
//     //   },
//     // },

//     {
//       field: "sellerBankDetails",
//       headerName: "Seller Bank Details",
//       width: 300,
//       renderCell: (params) => {
//         const {
//           accountName,
//           accountNumber,
//           accountType,
//           branchName,
//           ifscCode,
//         } = params.row.sellerBankDetails || {};

//         const bankDetails = `
//           Account Name: ${accountName || "N/A"}
//           Account Number: ${accountNumber || "N/A"}
//           Account Type: ${accountType || "N/A"}
//           Branch Name: ${branchName || "N/A"}
//           IFSC Code: ${ifscCode || "N/A"}
//         `;

//         return (
//           <Tooltip title={<pre>{bankDetails}</pre>} arrow>
//             <Typography variant="body2">
//               {accountName || "N/A"} - {accountType || "N/A"}
//             </Typography>
//           </Tooltip>
//         );
//       },
//     },

    
//     {
//       field: "sellerPriceINR",
//       headerName: "Seller Price (INR)",
//       width: 160,
//       valueGetter: (params) => params.row.sellerPayment?.amountINR,
//     },
//     {
//       field: "sellerPriceUSD",
//       headerName: "Seller Price (USD)",
//       width: 160,
//       valueGetter: (params) => params.row.sellerPayment?.amountUSD,
//     },
//     {
//       field: "sellerStatus",
//       headerName: "Seller Status",
//       width: 160,
//       valueGetter: (params) => params.row.sellerPayment?.status,
//     },
//     {
//       field: "sellerTransactionId",
//       headerName: "Seller Transaction ID",
//       width: 160,
//       valueGetter: (params) => params.row.sellerPayment?.transactionId,
//     },
//     {
//       field: "creatorName",
//       headerName: "Royaltiy Name",
//       width: 200,
//       renderCell: (params) => {
//         const creator = params?.row?.creator;

//         if (creator?.id !== "N/A") {
//           return (
//             <>
//               <Typography variant="body2">{creator?.name || "N/A"}</Typography>
//             </>
//           );
//         }

//         return <Typography variant="body2">N/A</Typography>;
//       },
//     },
//     {
//       field: "creatorRole",
//       headerName: "Royaltiy Role",
//       width: 200,
//       renderCell: (params) => {
//         const creator = params?.row?.creator;

//         if (creator?.id !== "N/A") {
//           return (
//             <>
//               <Typography variant="body2">{creator?.role || "N/A"}</Typography>
//             </>
//           );
//         }

//         return <Typography variant="body2">N/A</Typography>;
//       },
//     },
//     {
//       field: "creatorPayout",
//       headerName: "Royaltiy Payout",
//       width: 200,
//       renderCell: (params) => {
//         const creator = params?.row?.creator;

//         if (creator?.id !== "N/A") {
//           return (
//             <>
//               <Typography variant="body2">{creator?.creatorPayout || "N/A"}</Typography>
//             </>
//           );
//         }

//         return <Typography variant="body2">N/A</Typography>;
//       },
//     },
//     {
//       field: "creatorBankDetails",
//       headerName: "Royaltiy Bank Details",
//       width: 300,
//       renderCell: (params) => {
//         const {
//           accountName,
//           accountNumber,
//           accountType,
//           branchName,
//           ifscCode,
//         } = params.row.creatorBankDetails || {};

//         const bankDetails = `
//           Account Name: ${accountName || "N/A"}
//           Account Number: ${accountNumber || "N/A"}
//           Account Type: ${accountType || "N/A"}
//           Branch Name: ${branchName || "N/A"}
//           IFSC Code: ${ifscCode || "N/A"}
//         `;

//         return (
//           <Tooltip title={<pre>{bankDetails}</pre>} arrow>
//             <Typography variant="body2">
//               {accountName || "N/A"} - {accountType || "N/A"}
//             </Typography>
//           </Tooltip>
//         );
//       },
//     },
//     {
//       field: "creatorWalletAddress",
//       headerName: "Royaltiy Wallet Address",
//       width: 200,
//       renderCell: (params) => {
//         const creator = params?.row?.creator;

//         if (creator?.id !== "N/A") {
//           return (
//             <>
//               <Typography variant="body2">{creator?.createrCryptoWallet || "N/A"}</Typography>
//             </>
//           );
//         }

//         return <Typography variant="body2">N/A</Typography>;
//       },
//     },
//     {
//       field: "createrPriceINR",
//       headerName: "Royalty Price (INR)",
//       width: 160,
//       valueGetter: (params) => params.row.creatorPayment.amountINR,
//     },
//     {
//       field: "createrPriceUSD",
//       headerName: "Royalty Price (USD)",
//       width: 160,
//       valueGetter: (params) => params.row.creatorPayment.amountUSD,
//     },
//     {
//       field: "createrStatus",
//       headerName: "Royalty Status",
//       width: 160,
//       valueGetter: (params) => params.row.creatorPayment.status,
//     },
//     {
//       field: "createrTransactionId",
//       headerName: "Royalty Transaction ID",
//       width: 160,
//       valueGetter: (params) => params.row.creatorPayment.transactionId,
//     },
    

//     {
//       field: "actions",
//       headerName: "Actions",
//       width: 200,
//       renderCell: (params) => (
//         <Stack direction="row" spacing={1}>
//           {params.row.creator.id !== "N/A" ? (
//             <Button
//               variant="contained"
//               onClick={() => {
//                 console.log("log", params.row.creator);
//                 setModalData({
//                   type: "Royalty Fee",
//                   orderId: params.row.orderId,
//                 });
//                 setOpenModal(true);
//               }}
//             >
//               Royalty Fee
//             </Button>
//           ) : (
//             <></>
//           )}

//           <Button
//             variant="contained"
//             onClick={() => {
//               setModalData({
//                 type: "Seller Fee",
//                 orderId: params.row.orderId,
//               });
//               setOpenModal(true);
//             }}
//           >
//             Seller Fee
//           </Button>
//         </Stack>
//       ),
//     },
//   ];

//   return (
//     <>
//       <SideBar />
//       <Body>
//         <Appbar />
//         <Paper elevation={0} sx={{ p: 2, borderRadius: 2, my: 2 }}>
//           <Stack direction="row" alignItems="center">
//             <Stack direction="row" alignItems="center" sx={{ flex: 1 }}>
//               <Typography variant="p">Orders</Typography>
//             </Stack>
//             <TextField
//               label="Search"
//               variant="outlined"
//               size="small"
//               value={searchQuery}
//               onChange={handleSearch}
//               sx={{ flexGrow: 1 }}
//             />
//             <CSVLink
//               data={csvData}
//               filename={`orders-${new Date().toISOString()}.csv`}
//             >
//               <Button variant="outlined">
//                 <CloudDownloadIcon sx={{ mr: 1 }} />
//                 Download Data
//               </Button>
//             </CSVLink>
//           </Stack>
//         </Paper>
//         <Paper
//           sx={{ height: "80%", width: "100%", overflowX: "auto" }}
//           elevation={0}
//         >
//           <DataGrid
//             getRowId={(row) => row.orderId}
//             rows={filteredOrders}
//             columns={columns}
//             pageSizeOptions={[25]}
//             disableRowSelectionOnClick
//             loading={loading}
//             disableColumnMenu
//           />
//         </Paper>
//         <Modal open={openModal} onClose={() => setOpenModal(false)}>
//           {renderModalContent()}
//         </Modal>
//       </Body>
//     </>
//   );
// }

// export default Table;

import React, { useEffect, useState } from "react";

import { Tooltip } from "@mui/material";
import {
  Button,
  CircularProgress,
  Modal,
  Paper,
  Stack,
  Typography,
  Box,
  TextField,
} from "@mui/material";
import { DataGrid } from "@mui/x-data-grid";
import { CSVLink } from "react-csv";
import CloudDownloadIcon from "@mui/icons-material/CloudDownload";
import Appbar from "../../ui/Appbar";
import Body from "../../ui/Body";
import SideBar from "../../ui/SideBar";
import Axios from "../../config/authAxios";

// Normalize Orders Data
const normalizeOrders = (orders) => {
  console.log("nor", orders);
  return orders.map((order) => ({
    orderId: order._id,
    buyer: {
      name: order?.buyerId?.name || "N/A",
      email: order?.buyerId?.email || "N/A",
    },
    seller: {
      name: order?.sellerId?.name || "N/A",
      email: order?.sellerId?.email || "N/A",
      role: order?.sellerId?.role || "N/A",
      sellerPayout: order?.sellerId?.payout || "N/A",
      sellercryptoWallet: order?.sellerId?.cryptoWallet || "N/A",
    },
    price: {
      inr: order?.price?.inr || "N/A",
      usd: order?.price?.usd || "N/A",
    },
    creator: {
      id: order?.creatorId?._id || "N/A",
      name: order?.creatorId?.name || "N/A",
      email: order?.creatorId?.email || "N/A",
      role: order?.creatorId?.role || "N/A",
      creatorPayout: order?.creatorId?.payout || "N/A",
      createrCryptoWallet: order?.creatorId || "N/A",
    },
    creatorPayment: {
      amountINR: order?.creatorPayment?.amountINR || "N/A",
      amountUSD: order?.creatorPayment?.amountUSD || "N/A",
      status: order?.creatorPayment?.status || "N/A",
      transactionId: order?.creatorPayment?.transactionId || "N/A",
    },
    sellerPayment: {
      amountINR: order?.sellerPayment?.amountINR || "N/A",
      amountUSD: order?.sellerPayment?.amountUSD || "N/A",
      status: order?.sellerPayment?.status || "N/A",
      transactionId: order?.sellerPayment?.transactionId || "N/A",
    },
    sellerBankDetails: {
      accountName: order?.sellerId?.bankDetail?.name,
      accountNumber: order?.sellerId?.bankDetail?.accountNumber,
      accountType: order?.sellerId?.bankDetail?.accountType,
      branchName: order?.sellerId?.bankDetail?.branchName,
      ifscCode: order?.sellerId?.bankDetail?.ifscCode,
      name: order?.sellerId?.bankDetails?.name,
    },
    creatorBankDetails: {
      accountName: order?.creatorId?.bankDetail?.name,
      accountNumber: order?.creatorId?.bankDetail?.accountNumber,
      accountType: order?.creatorId?.bankDetail?.accountType,
      branchName: order?.creatorId?.bankDetail?.branchName,
      ifscCode: order?.creatorId?.bankDetail?.ifscCode,
      name: order?.creatorId?.bankDetails?.name,
    },
    creatorPayments: {
      amountINR: order?.creatorPayment?.amountINR || "N/A",
      amountUSD: order?.creatorPayment?.amountUSD || "N/A",
    },
    royaltyFee: order?.clbId?.royaltyFee?.percentage || "N/A",
    sellerPayment: order?.sellerPayment || {},

    createdAt: new Date(order?.created_at).toLocaleDateString(),
  }));
};

const fetchOrders = async () => {
  try {
    const response = await Axios.get("/orders-management/get-order-management");
    console.log("ressssssssss", response.data.result);
    if (response) {
      return normalizeOrders(response.data.result);
    } else {
      console.error("Error fetching orders:", response.data.message);
      return [];
    }
  } catch (error) {
    console.error("API call failed:", error);
    return [];
  }
};

function Table() {
  const [orders, setOrders] = useState([]);
  const [loading, setLoading] = useState(true);
  const [csvData, setCsvData] = useState([]);
  const [openModal, setOpenModal] = useState(false);
  const [modalData, setModalData] = useState({});
  const [formData, setFormData] = useState({});
  const [submitting, setSubmitting] = useState(false);
  const [filteredOrders, setFilteredOrders] = useState([]);
  const [searchQuery, setSearchQuery] = useState("");
  const [paginationModel, setPaginationModel] = useState({
    page: 0,
    pageSize: 10,
  });

  useEffect(() => {
    const getOrders = async () => {
      const fetchedOrders = await fetchOrders();
      setOrders(fetchedOrders);
      setLoading(false);
    };
    getOrders();
  }, []);
  useEffect(() => {
    const lowercasedQuery = searchQuery.toLowerCase();

    const filtered = orders.filter((order) => {
      // Combine all searchable values into a single array
      const searchableValues = [
        order?.buyer?.name,
        order?.buyer?.email,
        order?.seller?.name,
        order?.seller?.email,
        order?.seller?.role,
        order?.seller?.sellerPayout,
        order?.seller?.sellerCryptoWallet,
        order?.creator?.name,
        order?.creator?.role,
        order?.creator?.email,
        order?.creator?.creatorPayout,
        order?.creator?.createrCryptoWallet,
        order?.sellerBankDetails?.accountName,
        order?.sellerBankDetails?.accountNumber,
        order?.sellerBankDetails?.accountType,
        order?.sellerBankDetails?.branchName,
        order?.sellerBankDetails?.ifscCode,
        order?.creatorBankDetails?.accountName,
        order?.creatorBankDetails?.accountNumber,
        order?.creatorBankDetails?.accountType,
        order?.creatorBankDetails?.branchName,
        order?.creatorBankDetails?.ifscCode,
        order?.creatorPayments?.amountINR,
        order?.creatorPayments?.amountUSD,
        order?.creatorPayment?.status,
        order?.creatorPayment?.transactionId,
        order?.sellerPayment?.amountINR,
        order?.sellerPayment?.amountUSD,
        order?.sellerPayment?.status,
        order?.sellerPayment?.transactionId,
        order?.price?.inr,
        order?.price?.usd,
        order?.royaltyFee,
        order?.orderId,
      ];

      // Check if any value matches the search query
      return searchableValues.some((value) =>
        value?.toString().toLowerCase().includes(lowercasedQuery)
      );
    });

    setFilteredOrders(filtered);
  }, [searchQuery, orders]);

  const handleSearch = (e) => {
    setSearchQuery(e.target.value);
  };

  useEffect(() => {
    console.log("oreders", orders);
    const csvData = orders.map((order) => ({
      OrderID: order.orderId,
      BuyerName: order.buyer.name,
      BuyerEmail: order.buyer.email,
      SellerName: order.seller.name,
      SellerEmail: order.seller.email,
      SellerRole: order.seller.role,
      CreatorDetails: order.creatorId,
      CreatorBank: order.creator.bank,
      PriceINR: order.price.inr,
      PriceUSD: order.price.usd,
      createrPriceINR: order.creatorPayment.amountINR,
      createrPriceUSD: order.creatorPayment.amountUSD,
      createrStatus: order.creatorPayment.status,
      createrTransactionId: order.creatorPayment.transactionId,
      sellerPriceINR: order.sellerPayment.amountINR,
      sellerPriceUSD: order.sellerPayment.amountUSD,
      sellerStatus: order.sellerPayment.status,
      sellerTransactionId: order.sellerPayment.transactionId,
      RoyaltyFeePercentage: order.royaltyFee,
      CreatedAt: order.createdAt,
    }));
    setCsvData(csvData);
  }, [orders]);

  const handleSubmit = async () => {
    setSubmitting(true);
    try {
      const payload = {
        orderId: modalData.orderId,
        status: "completed",
        ...(modalData.type === "Royalty Fee"
          ? { creatorTransId: formData.transactionId }
          : { sellerTransId: formData.transactionId }),
      };

      const response = await Axios.post(
        "/orders-management/update-transaction",
        payload
      );

      if (response && response.data) {
        // alert("Transaction updated successfully");
        setOpenModal(false);
        fetchOrders()
        window.location.reload()
      } else {
        // alert("Failed to update transaction");
      }
    } catch (error) {
      console.error("Error submitting transaction:", error);
      //   alert("An error occurred while updating the transaction");
    } finally {
      setSubmitting(false);
    }
  };

  const renderModalContent = () => (
    <Box
      sx={{
        position: "absolute",
        top: "50%",
        left: "50%",
        transform: "translate(-50%, -50%)",
        width: 400,
        bgcolor: "background.paper",
        boxShadow: 24,
        p: 4,
        borderRadius: 2,
      }}
    >
      <Typography variant="h6" sx={{ mb: 2 }}>
        {modalData.type}
      </Typography>
      <TextField
        fullWidth
        label="Transaction ID"
        value={formData.transactionId || ""}
        onChange={(e) =>
          setFormData({ ...formData, transactionId: e.target.value })
        }
        sx={{ mb: 2 }}
      />
      <Button
        variant="contained"
        fullWidth
        disabled={!formData.transactionId || submitting}
        onClick={handleSubmit}
      >
        {submitting ? <CircularProgress size={24} /> : "Submit"}
      </Button>
      <Button
        variant="outlined"
        fullWidth
        sx={{ mt: 1 }}
        onClick={() => {
          setOpenModal(false); // Close the modal
          setFormData({}); // Clear the input fields
          setModalData({}); // Optional: Reset modal-specific data
        }}
      >
        Cancel
      </Button>
    </Box>
  );

  const columns = [
    {
      field: "buyerName",
      headerName: "Buyer Name",
      width: 160,
      valueGetter: (params) => params.row.buyer.name,
    },
    {
      field: "buyerEmail",
      headerName: "Buyer Email",
      width: 200,
      valueGetter: (params) => params.row.buyer.email,
    },
    {
      field: "sellerName",
      headerName: "Seller Name",
      width: 160,
      valueGetter: (params) => params.row.seller.name,
    },
    {
      field: "sellerEmail",
      headerName: "Seller Email",
      width: 200,
      valueGetter: (params) => params.row.seller.email,
    },
    {
      field: "SellerRole",
      headerName: "Seller Role",
      width: 200,
      valueGetter: (params) => params.row.seller.role,
    },
    {
      field: "sellerPayout",
      headerName: "Seller Payout ",
      width: 200,
      renderCell: (params) => {
        const creator = params?.row?.seller;

        if (creator?.id !== "N/A") {
          return (
            <>
              <Typography variant="body2">
                {creator?.sellerPayout || "N/A"}
              </Typography>
            </>
          );
        }

        return <Typography variant="body2">N/A</Typography>;
      },
    },
    // {
    //   field: "sellerWalletAddress",
    //   headerName: "Seller Wallet Address",
    //   width: 200,
    //   renderCell: (params) => {
    //     const creator = params?.row?.seller;
    //      console.log("creator", creator)
    //     if (creator?.id !== "N/A") {
    //       return (
    //         <>
    //           <Typography variant="body2">
    //             {creator?.sellerCryptoWallet|| "N/A"}
    //           </Typography>
    //         </>
    //       );
    //     }

    //     return <Typography variant="body2">N/A</Typography>;
    //   },
    // },
    {
      field: "sellerWalletAddress",
      headerName: "Seller Wallet Address",
      width: 200,
      renderCell: (params) => {
        const creator = params?.row?.seller;
        console.log("seller Data:", creator); // Debugging
      
        // Extract wallet address safely
        const walletAddress = creator?.
        sellercryptoWallet?.walletAddress;
        console.log("walletaddress", walletAddress)
      
        return (
          <Typography variant="body2">
            {walletAddress ? walletAddress : "N/A"}
          </Typography>
        );
      }
      
    },
    
    // {
    //   field: "creatorPayment",
    //   headerName: "Creator Payment (INR)",
    //   width: 250,
    //   renderCell: (params) => {
    //     const { amountINR, amountUSD, status, transactionId } =
    //       params.row.creatorPayment || {};

    //     // Tooltip content
    //     const tooltipContent = `
    //       USD: ${amountUSD !== undefined ? amountUSD : "N/A"}
    //       Status: ${status || "N/A"}
    //       Transaction ID: ${transactionId || "N/A"}
    //     `;

    //     return (
    //       <Tooltip title={<pre>{tooltipContent}</pre>} arrow>
    //         <Typography variant="body2">
    //           {amountINR !== undefined ? amountINR : "N/A"}
    //         </Typography>
    //       </Tooltip>
    //     );
    //   },
    // },

    {
      field: "sellerBankDetails",
      headerName: "Seller Bank Details",
      width: 300,
      renderCell: (params) => {
        const {
          accountName,
          accountNumber,
          accountType,
          branchName,
          ifscCode,
        } = params.row.sellerBankDetails || {};

        const bankDetails = `
          Account Name: ${accountName || "N/A"}
          Account Number: ${accountNumber || "N/A"}
          Account Type: ${accountType || "N/A"}
          Branch Name: ${branchName || "N/A"}
          IFSC Code: ${ifscCode || "N/A"}
        `;

        return (
          <Tooltip title={<pre>{bankDetails}</pre>} arrow>
            <Typography variant="body2">
              {accountName || "N/A"} - {accountType || "N/A"}
            </Typography>
          </Tooltip>
        );
      },
    },

    {
      field: "sellerPriceINR",
      headerName: "Seller Price (INR)",
      width: 160,
      valueGetter: (params) => params.row.sellerPayment?.amountINR,
    },
    {
      field: "sellerPriceUSD",
      headerName: "Seller Price (USD)",
      width: 160,
      // valueGetter: (params) => params.row.sellerPayment?.amountUSD,
      valueGetter: (params) => {
        const amount = params.row.sellerPayment?.amountUSD;
        return amount ? amount.toFixed(2) : "0.00";
      },
    },
    {
      field: "sellerStatus",
      headerName: "Seller Status",
      width: 160,
      valueGetter: (params) => params.row.sellerPayment?.status,
    },
    {
      field: "sellerTransactionId",
      headerName: "Seller Transaction ID",
      width: 160,
      valueGetter: (params) => params.row.sellerPayment?.transactionId,
    },
    {
      field: "creatorName",
      headerName: "Royaltiy Name",
      width: 200,
      renderCell: (params) => {
        const creator = params?.row?.creator;

        if (creator?.id !== "N/A") {
          return (
            <>
              <Typography variant="body2">{creator?.name || "N/A"}</Typography>
            </>
          );
        }

        return <Typography variant="body2">N/A</Typography>;
      },
    },
    {
      field: "creatorRole",
      headerName: "Royaltiy Role",
      width: 200,
      renderCell: (params) => {
        const creator = params?.row?.creator;

        if (creator?.id !== "N/A") {
          return (
            <>
              <Typography variant="body2">{creator?.role || "N/A"}</Typography>
            </>
          );
        }

        return <Typography variant="body2">N/A</Typography>;
      },
    },
    {
      field: "creatorPayout",
      headerName: "Royaltiy Payout",
      width: 200,
      renderCell: (params) => {
        const creator = params?.row?.creator;

        if (creator?.id !== "N/A") {
          return (
            <>
              <Typography variant="body2">
                {creator?.creatorPayout || "N/A"}
              </Typography>
            </>
          );
        }

        return <Typography variant="body2">N/A</Typography>;
      },
    },
    {
      field: "creatorBankDetails",
      headerName: "Royaltiy Bank Details",
      width: 300,
      renderCell: (params) => {
        const {
          accountName,
          accountNumber,
          accountType,
          branchName,
          ifscCode,
        } = params.row.creatorBankDetails || {};

        const bankDetails = `
          Account Name: ${accountName || "N/A"}
          Account Number: ${accountNumber || "N/A"}
          Account Type: ${accountType || "N/A"}
          Branch Name: ${branchName || "N/A"}
          IFSC Code: ${ifscCode || "N/A"}
        `;

        return (
          <Tooltip title={<pre>{bankDetails}</pre>} arrow>
            <Typography variant="body2">
              {accountName || "N/A"} - {accountType || "N/A"}
            </Typography>
          </Tooltip>
        );
      },
    },
    // {
    //   field: "creatorWalletAddress",
    //   headerName: "Royaltiy Wallet Address",
    //   width: 200,
    //   renderCell: (params) => {
    //     const creator = params?.row?.creator;

    //     if (creator?.id !== "N/A") {
    //       return (
    //         <>
    //           <Typography variant="body2">
    //             {creator?.createrCryptoWallet || "N/A"}
    //           </Typography>
    //         </>
    //       );
    //     }

    //     return <Typography variant="body2">N/A</Typography>;
    //   },
    // },
    {
      field: "creatorWalletAddress",
      headerName: "Royalty Wallet Address",
      width: 200,
      renderCell: (params) => {
        const creator = params?.row?.creator;
        console.log("royality", creator)
    
        const walletAddress = creator?.createrCryptoWallet?.cryptoWallet?.walletAddress;

    
    
        if (creator?.id !== "N/A" && typeof walletAddress === "string") {
          return <Typography variant="body2">{walletAddress}</Typography>;
        }
    
        return <Typography variant="body2">N/A</Typography>;
      },
    },
    
    {
      field: "createrPriceINR",
      headerName: "Royalty Price (INR)",
      width: 160,
      valueGetter: (params) => params.row.creatorPayment.amountINR,
    },
    // {
    //   field: "createrPriceUSD",
    //   headerName: "Royalty Price (USD)",
    //   width: 160,
    //   valueGetter: (params) => params.row.creatorPayment.amountUSD,
    //   // valueGetter: (params) => {
    //   //   const amount = params.row.creatorPayment.amountUSD;
    //   //   return amount ? amount.toFixed(2) : "0.00";
    //   // },
    // },
    {
      field: "createrPriceUSD",
      headerName: "Royalty Price (USD)",
      width: 160,
      valueGetter: (params) => {
        const amount = params.row.creatorPayment?.amountUSD;
        return typeof amount === "number" ? amount.toFixed(2) : "0.00";
      },
    },
    
    
    {
      field: "createrStatus",
      headerName: "Royalty Status",
      width: 160,
      valueGetter: (params) => params.row.creatorPayment.status,
    },
    {
      field: "createrTransactionId",
      headerName: "Royalty Transaction ID",
      width: 160,
      valueGetter: (params) => params.row.creatorPayment.transactionId,
    },

    // {
    //   field: "actions",
    //   headerName: "Actions",
    //   width: 200,
    //   renderCell: (params) => (
    //     <Stack direction="row" spacing={1}>
    //       {params.row.creator.id !== "N/A" ? (
    //         <Button
    //           variant="contained"
    //           onClick={() => {
    //             console.log("log", params.row.creator);
    //             setModalData({
    //               type: "Royalty Fee",
    //               orderId: params.row.orderId,
    //             });
    //             setOpenModal(true);
    //           }}
    //         >
    //           Royalty Fee
    //         </Button>
    //       ) : (
    //         <></>
    //       )}

    //       <Button
    //         variant="contained"
    //         onClick={() => {
    //           setModalData({
    //             type: "Seller Fee",
    //             orderId: params.row.orderId,
    //           });
    //           setOpenModal(true);
    //         }}
    //       >
    //         Seller Fee
    //       </Button>
    //     </Stack>
    //   ),
    // },
    {
      field: "actions",
      headerName: "Actions",
      width: 200,
      renderCell: (params) => {
        const isRoyaltyPending = params.row.creatorPayment?.status === "pending";
        const isSellerPending = params.row.sellerPayment?.status === "pending";
    
        return (
          <Stack direction="row" spacing={1}>
            {params.row.creator?.id !== "N/A" && (
              <Button
                variant="contained"
                onClick={() => {
                  setModalData({
                    type: "Royalty Fee",
                    orderId: params.row.orderId,
                  });
                  setOpenModal(true);
                }}
                disabled={!isRoyaltyPending}
              >
                {isRoyaltyPending ? "Royalty Fee" : "Completed"}
              </Button>
            )}
    
            <Button
              variant="contained"
              onClick={() => {
                setModalData({
                  type: "Seller Fee",
                  orderId: params.row.orderId,
                });
                setOpenModal(true);
              }}
              disabled={!isSellerPending}
            >
              {isSellerPending ? "Seller Fee" : "Completed"}
            </Button>
          </Stack>
        );
      },
    }
    
  ];


  return (
    <>
      <SideBar />
      <Body>
        <Appbar />
        <Paper elevation={0} sx={{ p: 2, borderRadius: 2, my: 2 }}>
          <Stack direction="row" alignItems="center">
            <Stack direction="row" alignItems="center" sx={{ flex: 1 }}>
              <Typography variant="p">Orders</Typography>
            </Stack>
            <TextField
              label="Search"
              variant="outlined"
              size="small"
              value={searchQuery}
              onChange={handleSearch}
              sx={{ flexGrow: 1 }}
            />
            <CSVLink
              data={csvData}
              filename={`orders-${new Date().toISOString()}.csv`}
            >
              {/* <Button variant="outlined">
                <CloudDownloadIcon sx={{ mr: 1 }} />
                Download Data
              </Button> */}
            </CSVLink>
          </Stack>
        </Paper>
        <Paper
          sx={{ height: "80%", width: "100%", overflowX: "auto" }}
          elevation={0}
        >
          <DataGrid
            getRowId={(row) => row.orderId}
            rows={filteredOrders}
            columns={columns}
            pageSizeOptions={[10, 25, 50]}
          pagination
          paginationMode="client"
          paginationModel={paginationModel}
          onPaginationModelChange={setPaginationModel}
          
            disableRowSelectionOnClick
            loading={loading}
            disableColumnMenu
          />
        </Paper>
        <Modal open={openModal} onClose={() => setOpenModal(false)}>
          {renderModalContent()}
        </Modal>
      </Body>
    </>
  );
}

export default Table;